@import "../../variables.scss";


.home-about-container {
    padding: 50px 0;
    display: flex;
    justify-content: center;

    .home-about-img {
        width: 400px;
        height: 400px;
        object-fit: cover;
        object-position: center;
        box-shadow:
            -50px -50px 0 -40px $primary-color,
            50px 50px 0 -40px $primary-color;
    }

    .home-about-description {
        width: 600px;
        height: 400px;
        display: flex;
        flex-direction: column;
        padding: 30px 50px;
        justify-content: space-between;
        p:first-child {
            font-size: 30px;
            font-weight: bold;
        }
        p:nth-child(2) {
            font-size: 20px;
            text-align: justify;
        }
        button {
            width: 40%;
            height: 50px;
            border: none;
            border-radius: 10px;
            background-color: $primary-color;
            color: white;
            font-size: 20px;
            align-self: center;
            transition: 0.1s;
            flex-shrink: 0;
            &:hover {
                cursor: pointer;
                background-color: red;
            }
        }
    }
}

@media (max-width: $breakpoint-desktop-narrow) {
    .home-about-container {

        .home-about-img {
            width: 300px;
            height: 300px;
            
        }
    
        .home-about-description {
            width: 500px;
            height: 300px;
            padding: 0 30px;
            p:first-child {
                font-size: 30px;
            }
            p:nth-child(2) {
                font-size: 18px;
            }
            button {
                width: 50%;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .home-about-container {
        .home-about-img {
            width: 30vw;
            height: 30vw;
            
        }
    
        .home-about-description {
            width: 60vw;
            height: 30vw;
            padding: 0 30px;
            p:first-child {
                font-size: 25px;
            }
            p:nth-child(2) {
                font-size: 15px;
            }
            button {
                width: 60%;
                font-size: 18px;
            }
        }
    }
}

@media (max-width: $breakpoint-smartphone) {
    .home-about-container {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 650px;
        .home-about-img {
            width: 90vw;
            height: 50vw;
        }
        .home-about-description {
            width: 90vw;
            height: 250px;
            padding: 0;
            p:first-child {
                font-size: 25px;
            }
            p:nth-child(2) {
                font-size: 18px;
            }
            button {
                width: 60%;
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 500px) {
    .home-about-container {
        height: 600px;
    }
}

@media (max-width: 400px) {
    .home-about-container {
        height: 560px;
    }
}