@import "../../variables.scss";

.home-why-container {
    margin: 100px 10px;
    display: flex;
    flex-direction: column;

    p:first-child {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
    }

    .why-items-wrapper {
        display: flex;
        width: 90%;
        justify-content: space-around;
        align-self: center;
        margin-top: 70px;
        .why-item {
            display: flex;
            flex-direction: column;
            flex-basis: 30%;
            align-items: center;
            .why-icon {
                width: 50px;
                height: 50px;
                fill: $primary-color;
            }

            .why-item-title {
                font-size: 25px;
                margin-top: 20px;
            }

            .why-item-desc {
                text-align: justify;
                margin-top: 22px;
            }
        }
        
    }
}


@media (max-width: $breakpoint-tablet) {
    .home-why-container {   
        .why-items-wrapper {
            width: 70%;
            margin-top: 10px;
            flex-direction: column;
            .why-item {
                margin-top: 50px;
            }            
        }
    }
}

@media (max-width: $breakpoint-smartphone) {
    .home-why-container {   
        .why-items-wrapper {
            width: 90%;          
        }
    }
}