
@import "../../variables.scss";


.nav-menu-container {
    flex-grow: 1;
    .nav-menu-desktop {
        display: flex;
        justify-content: center;
        a {
            position: relative;
            display: block;
            font-size: 15px;
            margin: 0 30px;
            color: white;
            text-decoration: none;
            text-align: center;
            transition: 0.3s;
    
            &::after {
                position: absolute;
                content: "";
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background: white;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 0.5s;
                margin-top: 2px;
            }
                
            &:hover::after {
                transform: scaleX(1);
                transform-origin: left;
            }
        }
        
        a.active {
            &::after {
                transform: scaleX(1);;
                transform-origin: left;
            }
        }
    }  
    .nav-menu-smartphone {
        display: none;
    }  
}

@media (max-width: $breakpoint-desktop-narrow) {
    .nav-menu-container {
        .nav-menu-desktop {
            a {
                font-size: 12px;
                margin: 0 20px;
    
                &::after {
                    height: 1px;
                }
            } 
        }
                   
    }
}

@media (max-width: $breakpoint-tablet) {
    .nav-menu-container {
        .nav-menu-desktop {
            a {
                font-size: 8px;
                margin: 0 10px;
    
                &::after {
                    height: 1px;
                }
            }
        }
                    
    }
}

@media (max-width: $breakpoint-smartphone) {
    .nav-menu-container {
        flex-grow: 0;
        .nav-menu-desktop {
            display: none;
        }
        .nav-menu-smartphone {
            display: block;
        }
    }
}