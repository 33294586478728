@import "../../variables.scss";

.header-contact-bar-container {
    position: absolute;
    width: 280px;
    top: 5px;
    right: 20px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    .contact-container {
        display: flex;
        align-items: center;
        height: 20px;
        svg {
            color: $primary-color;
            margin-right: 5px;
            width: 15px;
            height: 15px;
        }

        a {
            color: black;
            font-weight: bold;
            text-decoration: none;
            margin-right: 10px;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media(max-width: $breakpoint-smartphone) {
    .header-contact-bar-container {
        display: none;
    }
}