@import "../../variables.scss";

.header-container {
    width: 100%;
    height: 100px;
    position: relative;
    overflow: hidden;
    .header-middle-bar {
        width: 100%;
        height: 45px;
        background-color: $primary-color;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }
    .logo-menu-container {
        width: 90%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
            width: 300px;
            cursor: pointer;
            animation-name: intro-anim-logo;
            animation-duration: 4s;
        }
    }
}

@media (max-width: $breakpoint-desktop-narrow) {
    .header-container{
        .header-middle-bar {
            height: 30px;
        }

        .logo-menu-container {
            img {
                width: 250px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .header-container{
        .header-middle-bar {
            height: 30px;
        }

        .logo-menu-container {
            img {
                width: 200px;
            }
        }
    }
}

@media (max-width: $breakpoint-smartphone) {
    .header-container {
        border-bottom: 1px solid $primary-color;
        height: 60px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        transition: 0.2s;
        .header-middle-bar {
            display: none;
        }        
        .logo-menu-container {
            position: relative;
            width: 90%;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            justify-content: space-between;
            img {
                width: 180px;
                position: relative;
            }
        }
    }

    .burger-menu-opened {
        height: 375px;
    }
}
