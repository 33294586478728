@import "../../variables.scss";

.footer-container {
    width: 100%;
    background-color: #585858;
    padding: 20px 20px;
    display: flex;
    justify-content: space-around;
    color: rgb(211, 211, 211);

    .footer-company-info {
        width: 30%;
        align-self: center;
        .footer-general-info {
            padding-bottom: 20px;
            p {
                a {
                    color: rgb(211, 211, 211);
                    text-decoration: none;
                    &:hover {
                        color: white;
                    }
                }
            }
            p:nth-child(1) {
                font-size: 20px;
            }
        }
       
    }
    .footer-map {
        width: 60%;
        p {
            width: 100%;
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .map-container {
            width: 100%;
            height: 300px;
        }
        
    }
}


@media (max-width: $breakpoint-tablet) {
    .footer-container {
        flex-direction: column;
        padding: 10px 5px;

        .footer-company-info {
            width: 100%;
            margin-bottom: 50px;
        }

        .footer-map {
            width: 100%;
        }
    }
}