@import "../../variables.scss";

.home-welcome-container {
    position: relative;
    max-width: 100%;
    width: 100vw;
    height: 100vh;
    .home-welcome-box {
        width: 50%;
        height: 60%;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 30px 0;

        p:nth-child(1) {
            font-size: 50px;
        }
        p:nth-child(2) {
            font-size: 30px;
            text-align: center;
        }

        div {
            width: 90%;
            height: 30px;
            display: flex;
            align-items: center;
            div.item-wrapper {
                display: flex;
                width: 30%;
                justify-content: center;
                i {
                    width: 30px;
                    height: 30px;
                    .home-welcome-icon {
                        width: 30px;
                        height: 30px;
                        fill: $primary-color;
                    }
                }
                span {
                    line-height: 30px;
                    margin-left: 5px;
                    font-size: 20px;
                }
                &:nth-child(2) {
                    flex-grow: 1;
                }
            }
        }

        button {
            font-size: 20px;
            color: white;
            background-color: $primary-color;
            border: none;
            border-radius: 10px;
            padding: 10px 20px;
            transition: 0.1s;
            opacity: 1;
            &:hover {
                cursor: pointer;
                background-color: red;
            }
        }
    }
}

@media (max-width: $breakpoint-desktop-middle) {
    .home-welcome-container {    
        .home-welcome-box {
            p:nth-child(2) {
                font-size: 25px;
            }               
            div {                
                div.item-wrapper {    
                    i {
                        width: 20px;
                        height: 20px;
                        .home-welcome-icon {
                            width: 20px;
                            height: 20px;
                        }
                    }                
                 span {
                        font-size: 15px;
                    }
                }
            }    
            button {
                font-size: 15px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .home-welcome-container {    
        .home-welcome-box {
            width: 90%;
            left: 50%;
            transform: translate(-50%, -50%);
            div {
                width: 95%;
            }
        }
    }
}

@media (max-width: $breakpoint-smartphone) {
    .home-welcome-container {    
        .home-welcome-box {
            width: 90%;
            height: 80%;
            left: 50%;
            transform: translate(-50%, -50%);
            div {
                flex-basis: 30%;
                flex-direction: column;
                div.item-wrapper {
                    justify-content: flex-start;
                    flex-direction: row;
                    width: 70%;
                }
            }
        }
    }
}